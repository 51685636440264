<template>
  <Toast position="top-center" group="ok">
    <template #message="slotProps">
      <div class="flex flex-column">
        <div class="p-d-flex p-ai-center p-jc-center">
          <i class="pi pi-lock p-mr-4" style="font-size: 3rem"></i>
          <div class="text-center">
            <h4>{{ slotProps.message.summary }}</h4>
            <p>
              Senha recuperada! Agora sua senha é <b> {{ email }}</b> ao efetuar
              login troque sua senha.
            </p>
          </div>
        </div>
        <br />
        <div class="grid p-fluid">
          <Button
            class="p-button-info"
            label="Efeturar login"
            @click="$router.push('/login')"
          ></Button>
        </div>
      </div>
    </template>
  </Toast>

  <div class="full">
    <div class="p-col-12 fullScreen">
      <div class="p-shadow-4 formlogin login">
        <div class="headerForm"></div>
        <div class="bodyForm">
          <div class="p-d-flex p-ai-center p-jc-center p-fluid p-grid">
            <div class="p-field p-shadow-4 formulario">
              <div class="p-d-flex p-ai-center p-jc-center">
                <img src="../../assets/logoEstado.svg" class="ctis" />
              </div>
              <br />
              <div class="p-d-flex p-ai-center p-jc-center">
                <h1>Recuperação de Senha.</h1>
              </div>
              <div class="p-d-flex p-ai-center p-jc-center">
                <h4>{{ email }}</h4>
              </div>

              <div class="p-d-flex p-ai-center p-jc-center">
                <div class="p-col-12 p-md-6">
                  <Button
                    :disabled="!email"
                    v-tooltip.right="'Clique para recuperar senha.'"
                    label="Recuperar Senha"
                    class="p-button-raised p-button-success hvr-grow"
                    @click="resetPassword()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersService from "@/service/Users/UsersService";

export default {
  data() {
    return {
      email: this.$route.query.email,
    };
  },
  usersService: null,
  created() {
    this.usersService = new UsersService();
  },
  methods: {
    resetPassword() {
      this.usersService
        .restorePassword(this.email)
        .then((data) => {
          if (data.status === 204) {
            this.$toast.add({
              severity: "success",
              summary: "Senha Recuperada.",
              group: "ok",
            });
          }
        })
        .catch((error) => {
          this.msg = false;
          this.$msgErro(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}
.fullScreen {
  height: 99vh;
  overflow: hidden;
}
.formlogin {
  height: 100%;
  margin-left: 10px;
}
.login {
  background-image: url("../../assets/fundo.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover*/
}
.headerForm {
  height: 30%;
}
.bodyForm {
  height: 70%;
}
.formulario {
  width: 25%;
  padding: 3%;
}
.ctis {
  width: 70%;
}
.full {
  margin: 0px auto;
}
@media screen and (max-width: 40em) {
  h1 {
    font-size: 25px;
  }
  h4 {
    font-size: 18px;
  }

  .painelGeral {
    display: none !important;
  }
  .formulario {
    width: 90%;
    padding: 3%;
  }
  .headerForm {
    height: 3%;
  }
  .bodyForm {
    height: 97%;
  }
  .ctis {
    width: 80%;
  }
}
@media screen and (max-width: 70em) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 18px;
  }

  .painelGeral {
    display: none !important;
  }
  .formulario {
    width: 90%;
    padding: 3%;
  }
  .headerForm {
    height: 3%;
  }
  .bodyForm {
    height: 97%;
  }
  .ctis {
    width: 80%;
  }
}
</style>